import {
  Component,
  ChangeDetectionStrategy,
  inject,
  signal,
  Inject,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogModule,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { NgOtpInputModule } from 'ng-otp-input';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  combineLatest,
  interval,
  Observable,
  of,
  Subscription,
  switchMap,
  throwError,
} from 'rxjs';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { AuthService } from '../../../../modules/auth/auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConstantService } from '../../../services/constant.service';
import { EncryptionService } from '../../../services/encryption.service';

@Component({
  selector: 'app-otp-verification',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    MatDialogModule,
    NgOtpInputModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  styleUrls: ['./otp-verification.component.scss'],
  templateUrl: './otp-verification.component.html',
})
export class OtpVerificationComponent {
  private authService = inject(AuthService);
  private fb = inject(NonNullableFormBuilder);
  private dialogRef = inject(MatDialogRef<OtpVerificationComponent>);
  private toastr = inject(ToastrService);
  private constantService = inject(ConstantService);
  private subscription!: Subscription;

  public sendSubmitDisabled = signal<boolean>(false);
  public sendResendDisabled = signal<boolean>(true);
  private translate = inject(TranslateService);

  private remainingTime: number = 120000;
  public formattedRemainingTime!: string;
  private timerSubscription!: Subscription;
  private encDecObj = inject(EncryptionService);
  public modalData: any;

  /**
   * Otp form initialization
   */
  otpForm = this.fb.group({
    user_id: [0, Validators.required],
    otp: ['', Validators.required],
    type: ['1'],
    protectWith: ['1'],
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.otpForm.patchValue({
      user_id: data.user_id,
      type: data.type,
      protectWith: data.protectWith,
    });
    this.modalData = { ...data };
  }

  ngOnInit() {
    this.updateRemainingTime();
    if (this.timerSubscription) this.timerSubscription.unsubscribe();
    this.timerSubscription = interval(1000).subscribe(() =>
      this.updateRemainingTime()
    );
  }

  /**
   * OTP resend time updates
   */
  updateRemainingTime() {
    if (this.remainingTime > 0) {
      this.remainingTime -= 1000;
      this.formattedRemainingTime = this.formatTime(this.remainingTime);
    } else {
      this.setResendDisabled(false);
    }
  }

  /**
   * Time formation
   */
  formatTime(time: number): string {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''
      }${seconds}`;
  }

  /**
   * otp change event
   */
  onOtpChange(event: any) {
    this.otpForm.patchValue({ otp: event });
  }

  /**
   * Disabled submit button
   */
  setSubmitDisabled(value: boolean): void {
    this.sendSubmitDisabled.set(value);
  }

  /**
   * Disabled resend button
   */
  setResendDisabled(value: boolean): void {
    this.sendResendDisabled.set(value);
  }

  /**
   * gets otp and send to sign-in
   */
  verify() {
    this.otpForm.markAllAsTouched();
    if (this.otpForm.invalid) {
      this.constantService.showError('enter_otp');
      return;
    }

    // API => 1: verfiy-protection-token; 2: verfiy-otp;
    this.setSubmitDisabled(true);
    if (this.modalData.api === 1) {
      this.commonVerify(this.authService.verifyProtectionToken(this.otpForm.value));
    } else if (this.modalData.api === 2) {
      this.commonVerify(this.authService.verifyOTP(this.otpForm.value));
    }
  }

  /**
   * API response
   */
  commonVerify(observable: Observable<ResponseMessage>) {
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription = observable
      .pipe(
        catchError((err) => {
          this.toastr.error(err.error.message);
          this.setSubmitDisabled(false);
          return throwError(() => err.error.message);
        })
      )
      .subscribe((res) => {
        this.setSubmitDisabled(false);
        if (res.code === 200) {
          if (res?.data?.token) {
            const userDetail = this.encDecObj.setEncrypt(JSON.stringify(res.data));
            localStorage.setItem('userDetail', userDetail);
          }
          this.dialogRef.close({
            success: true,
            res: res
          });
        } else {
          this.constantService.showError('invalid_otp');
        }
      });
  }

  /**
   * Call Resend OTP API
   */
  resend() {
    if (this.subscription) this.subscription.unsubscribe();
    this.setSubmitDisabled(true);
    this.setResendDisabled(true);
    let obj = {
      user_id: this.modalData.user_id,
      type: this.modalData.is_send_from == 2 ? this.modalData.type : '', // 1 = Email,2=phone
      is_send_from: this.modalData.is_send_from //1= login, 2 = Signup 3 = account protection
    }
    this.subscription = this.authService
      .resendOTP(obj)
      .pipe(
        switchMap((res: ResponseMessage) =>
          combineLatest([of(res), this.translate.get('otp_sent')])
        ),
        catchError((err) => {
          this.toastr.error(err.error.message);
          this.setSubmitDisabled(false);
          this.setResendDisabled(true);
          return throwError(() => err.error.message);
        })
      )
      .subscribe(([res, otpSent]) => {
        this.setSubmitDisabled(false);
        this.setResendDisabled(true);
        if (res.code == 200) {
          this.remainingTime = 120000;
          this.toastr.success(otpSent);
        }
      });
  }

  /**
   * Close modal
   */
  close() {
    this.dialogRef.close(
      {
        success: false
      }
    );
  }
}
