import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { catchError, Subscription, tap, throwError } from 'rxjs';
import { Loader } from '@googlemaps/js-api-loader';

// Modules
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { Router, RouterModule } from '@angular/router';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { SignupBusinessDetailsComponent } from '../signup-business-details/signup-business-details.component';
import { SignupAccountProtectionComponent } from '../signup-account-protection/signup-account-protection.component';

// Interfaces

// Const

// Services
import { AuthService } from '../auth.service';
import {
  bankAccountType,
  googlePlaceAPIKey,
} from '../../../model/const/common.const';
import {
  PasswordFieldType,
  BusinessDetailFormValues,
} from '../../../model/interfaces/auth.interface';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { emailOrNumberValidator } from '../../../shared/validators/email-or-number-validator.directive';
import { numberValidator } from '../../../shared/validators/number-validator.directive';
import { passwordMatchValidator } from '../../../shared/validators/password-match-validator.directive';
import { ConstantService } from '../../../shared/services/constant.service';
import { EncryptionService } from '../../../shared/services/encryption.service';
import { emailValidator } from '../../../shared/validators/email-validator.directive';
import { passwordValidator } from '../../../shared/validators/password-validator.directive';
import { MatSelectModule } from '@angular/material/select';
import { NumberOnlyDirective } from '../../../shared/directives/number-only.directive';
import { OtpVerificationComponent } from '../../../shared/components/dialog/otp-verification/otp-verification.component';
import { AccountProtectionComponent } from '../account-protection/account-protection.component';
import { PrimarySetupComponent } from '../primary-setup/primary-setup.component';

// Validators

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxGpAutocompleteModule,
    NgxMatIntlTelInputComponent,
    MatButtonModule,
    MatCheckbox,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioButton,
    MatRadioGroup,
    SignupBusinessDetailsComponent,
    RouterModule,
    MatSelectModule,
    TranslateModule,
    NumberOnlyDirective,
    AccountProtectionComponent,
    PrimarySetupComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [
    EncryptionService,
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: googlePlaceAPIKey,
        libraries: ['places'],
      }),
    },
  ],
})
export class SignupComponent implements OnInit {
  private toastr = inject(ToastrService);
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  readonly dialog = inject(MatDialog);
  private subscription!: Subscription;
  private constantService = inject(ConstantService);
  public passwordFieldType = signal<PasswordFieldType>('password');
  public confirmPasswordFieldType = signal<PasswordFieldType>('password');
  public currentStep = signal<number>(1);
  public isEmail = signal<boolean>(true);
  public preferredCountries = signal<Array<string>>(['us']);
  public submitDisabled = signal<boolean>(true);
  public sendOTPDisabled = signal<boolean>(false);
  public businessDetailFormData = signal<BusinessDetailFormValues | null>(null);
  private encDecObj = inject(EncryptionService);
  private route = inject(Router);
  @ViewChild('location') location!: ElementRef;

  public signupForm = this.fb.group(
    {
      user_id: [0, []],
      otp: ['', []],
      expiry_time: ['', []],
      userName: ['', [Validators.required, emailValidator()]],
      phone: ['', []],
      vendorName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6), passwordValidator()]],
      confirmPassword: ['', [Validators.required]],
      location: ['', Validators.required],
      latitude: [0, Validators.required],
      longitude: [0, Validators.required],
      acceptTerm: ['', [Validators.requiredTrue]],
    },
    { validators: passwordMatchValidator }
  );

  public bankAccountForm = this.fb.group({
    idProof: [''],
    bankName: ['', Validators.required],
    routingNumber: ['', Validators.required],
    accountNumber: ['', Validators.required],
    accountType: ['', Validators.required],
    accountHolderName: ['', Validators.required],
  });
  public bankAccountType = bankAccountType;
  fileTypeError = '';
  imageFileName: any;
  @ViewChild('fileInput') fileInput!: ElementRef;
  imageFile: any;
  profileImageUrl: any;
  public submitBankAccountDisabled = signal<boolean>(false);
  private subscribe!: Subscription;
  private router = inject(Router);
  loggeedIn = signal(localStorage.getItem('token') ? true : false);
  profileCompleteStatus$ = this.authService.getProfileCompleteStatus();

  ngOnInit() {
    if (localStorage.getItem('currentStep')) {
      this.setCurrentStep(JSON.parse(localStorage.getItem('currentStep') as any))
    }
    this.setModuleTitle();
    this.authService.getCountryName().subscribe((response) => {
      this.preferredCountries.set([response.country.toLowerCase()]);
    });
  }

  onInputChange(value: boolean): void {
    this.isEmail.set(value);
    this.signupForm.patchValue({
      userName: '',
      phone: '',
    });
    if (value) {
      this.signupForm.controls.userName.setValidators([
        Validators.required,
        emailValidator(),
      ]);
      this.signupForm.controls.phone.setValidators([]);
    } else {
      this.signupForm.controls.userName.setValidators([]);
      this.signupForm.controls.phone.setValidators([
        Validators.required,
        numberValidator(),
      ]);
    }
    this.signupForm.controls.userName.updateValueAndValidity();
    this.signupForm.controls.phone.updateValueAndValidity();
    this.resetUserEmailPhone();
  }

  togglePasswordVisibility(field: 'password' | 'confirmPassword'): void {
    if (field === 'password') {
      this.passwordFieldType.set(
        this.passwordFieldType() === 'password' ? 'text' : 'password'
      );
    } else if (field === 'confirmPassword') {
      this.confirmPasswordFieldType.set(
        this.confirmPasswordFieldType() === 'password' ? 'text' : 'password'
      );
    }
  }

  handleAddressChange(place: google.maps.places.PlaceResult): void {
    this.signupForm.patchValue({
      location: this.location.nativeElement.value,
      latitude: place.geometry?.location?.lat(),
      longitude: place.geometry?.location?.lng(),
    });
  }

  setCurrentStep(value: number): void {
    this.currentStep.set(value);
    let loggeedIn = localStorage.getItem('token') ? true : false;
    this.loggeedIn.set(loggeedIn)
  }

  setSendOTPDisabled(value: boolean): void {
    this.sendOTPDisabled.set(value);
  }

  setSubmitDisabledDisabled(value: boolean): void {
    this.submitDisabled.set(value);
  }

  resetUserEmailPhone(): void {
    this.setSendOTPDisabled(false);
    this.setSubmitDisabledDisabled(true);
  }
  setToken(route?: any) {
    let userDetail = localStorage.getItem('userDetail');
    let token = JSON.parse(this.encDecObj.getDecrypt(userDetail)).token;
    localStorage.setItem('token', token);
    if (route) this.route.navigate([route]);
  }
  redirectToDashboard() {
    this.setToken('/dashboard')
  }
  redirectToMenu() {
    this.setToken('/menu/create')
  }
  openDialog(user_id: number, type: string): void {
    const dialogRef = this.dialog.open(OtpVerificationComponent, {
      width: '500px',
      disableClose: true,
      data: {
        user_id,
        type,
        title: 'sign_step_one.verify.title',
        sub_title: 'sign_step_one.verify.sub_title',
        dont_receive: 'sign_step_one.verify.dont_receive',
        api: 2,
        resend: true,
        is_send_from: 2, //1= login, 2 = Signup 3 = account protection
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.success) {

        // this.authService.setToken(result.res.data.token || '');
        // const userDetail = this.encDecObj.setEncrypt(JSON.stringify(result.res.data));
        // localStorage.setItem('userDetail', userDetail);
        this.setCurrentStep(2);
      } else {
        this.signupForm.controls.user_id.setValue(0);
      }
    });
  }

  sendOTP(): void {
    const value = this.signupForm.value;
    if (this.subscription) this.subscription.unsubscribe();
    const isEmail = this.isEmail();
    if (
      (isEmail && this.signupForm.controls.userName.valid) ||
      (!isEmail && this.signupForm.controls.phone.valid)
    ) {
      this.setSendOTPDisabled(true);
      const data = {
        email_phone_number: value.userName || value.phone,
        type: value.userName ? '1' : '2',
      };
      this.subscription = this.authService
        .sendOTP(data)
        .pipe(
          tap((res: ResponseMessage) => {
            this.setSubmitDisabledDisabled(false);
            this.signupForm.patchValue({
              otp: res.data.otp,
              expiry_time: res.data.expiry_time,
            });
            this.constantService.showSuccess('otp_sent');
          }),
          catchError((err) => {
            this.toastr.error(err.error.message);
            this.setSendOTPDisabled(false);
            return throwError(() => err.error.message);
          })
        )
        .subscribe();
    }
  }

  formSubmit(value: any): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.signupForm.invalid) {
      return;
    }
    if (this.signupForm.value.user_id) {
      this.openDialog(
        this.signupForm.value.user_id,
        value.userName ? '1' : '2'
      );
      return;
    }
    const data = {
      name: value.vendorName,
      email_phone_number: value.userName.toLowerCase() || value.phone,
      sign_up_type: value.userName ? '1' : '2',
      otp: value.otp,
      otp_expiry_time: value.expiry_time,
      password: value.password,
      password_confirmation: value.confirmPassword,
      user_type: 2, //1=>Super Admin,2=>Vendor Admin,3=>User
      location: value.location,
      latitude: value.latitude,
      longitude: value.longitude,
    };
    this.setSubmitDisabledDisabled(true);
    this.subscription = this.authService
      .signup(data)
      .pipe(
        tap((res: ResponseMessage) => {
          this.setSubmitDisabledDisabled(false);
          if (res.code === 200) {
            this.signupForm.patchValue({
              user_id: res.data.user_id,
            });
            this.openDialog(res.data.user_id, value.userName ? '1' : '2');
            const userDetail = this.encDecObj.setEncrypt(
              JSON.stringify(res.data)
            );
            localStorage.setItem('userDetail', userDetail);
            // localStorage.setItem('token', res.data.token)
          } else {
            this.toastr.error(res.message);
          }
        }),
        catchError((err) => {
          this.toastr.error(err.error.message);
          this.setSubmitDisabledDisabled(false);
          return throwError(() => err.error.message);
        })
      )
      .subscribe();
  }

  submittedBusinessDetails(dataForm: BusinessDetailFormValues) {
    this.businessDetailFormData.set(dataForm);
    this.setToken();
    this.setCurrentStep(3);
  }

  setModuleTitle() {
    this.constantService.setModuleTitle('Sign Up');
  }

  imageCheck() {
    this.fileInput.nativeElement.value = '';
    return null;
  }

  uploadImage(event: any) {
    const file = event.target.files[0];
    const fileSizeMB = file.size / (1024 * 1024);
    const imageExtensions = ['image/png', 'image/jpeg', 'image/jpg'];

    if (fileSizeMB > 5) {
      this.fileTypeError = 'image_file_5mb_validation';
      return;
    }

    if (!imageExtensions.includes(file.type.toLowerCase())) {
      this.fileTypeError = 'image_file_format_validation';
      return;
    }

    const reader = new FileReader();
    this.imageFile = file;
    this.fileTypeError = '';
    this.imageFileName = file.name;
    reader.readAsDataURL(this.imageFile);
    reader.onload = () => {
      this.profileImageUrl = reader.result;
    };
  }

  bankAccountFormSubmit(value: any) {
    this.bankAccountForm.markAllAsTouched();
    // if (!this.imageFile) {
    //   this.fileTypeError = ' ';
    //   return;
    // }
    if (this.bankAccountForm.invalid) return;

    let formData = new FormData();
    formData.append('bank_name', value.bankName);
    formData.append('routing_number', value.routingNumber);
    formData.append('account_number', value.accountNumber);
    formData.append('account_type', value.accountType);
    formData.append('account_holder_name', value.accountHolderName);
    if (this.imageFile && this.imageFile != undefined)
      formData.append('image', this.imageFile);

    this.submitBankAccountDisabled.set(true);
    if (this.subscribe) this.subscribe.unsubscribe();
    this.subscribe = this.authService
      .addEditBankAccount(formData)
      .pipe(
        catchError((error) => {
          this.submitBankAccountDisabled.set(false);
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })
      )
      .subscribe((res: ResponseMessage) => {
        this.submitBankAccountDisabled.set(false);
        if (res.code === 200) {
          this.toastr.success(res.message);
          // this.router.navigate(['dashboard']);
          this.setCurrentStep(4)
        } else {
          this.toastr.error(res.message);
        }
      });
  }
}
